<template>
    <div>
        <div class="head-m">
            <div class="head-logo-m">
                <img :src="imgObj.homeImg" width="50px" height="50px">
                <div class="head-logo-name-m">
                    <div class="appName-m">旺街邮</div>
                    <div class="appData-m">发货找车的物流货运平台</div>
                </div>
                <div class="zhojin"></div>
                <el-dropdown class="edr" trigger="click" @command="handleCommand">
                      <span class="el-dropdown-link">
                         <img class="navigation-m" :src="imgObj.navigation">
                      </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="dynamic">最新动态</el-dropdown-item>
                        <el-dropdown-item command="about">关于我们</el-dropdown-item>
                        <el-dropdown-item command="shipments">老板发货</el-dropdown-item>
                        <el-dropdown-item command="order">司机接单</el-dropdown-item>
                        <el-dropdown-item command="relation">联系我们</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
        <div class="  main-m">
            <div class=" ">
                <div class="fontBold">{{newData.title}}</div>
                <div class=" ">{{newData.createTime}}</div>
                <div v-html="newData.content"></div>
            </div>

        </div>
        <div>
            <div class="buttonLin_m"></div>
            <div class="button_m">
                <div class="flex-column_m">
                    <img class="logoImg_m" :src="imgObj.logoMin">
                    <div class="textName">杭州旺街网络科技有限公司</div>
                    <div class="text799">Copyright © 2018 杭州旺街网络科技 版权所有 </div>
                    <a href="https://beian.miit.gov.cn/" target="_blank">浙ICP备18030766号-1</a>
                    <div class="ganAn ">
                        <img class="badge_m" :src="imgObj.badge">
                        <a class="text799" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010902002583" target="_blank">浙公网安备 33010902002583号</a>

                    </div>
                </div>
                <div class="buttonLin-height-m"></div>
                <div class="flex-column-m ">
                    <div class="text14c33m  marginTop-10">电话：0571-82136687</div>
                    <div class="text14c33m  marginTop-10">客服微信：wangjiekefu88</div>
                    <div class="text14c33m  marginTop-10">邮箱：wonstreet@126.com</div>
                    <div class="text14c33m marginTop-10">地址：杭州市萧山区农业大厦1号楼25层</div>
                </div>
                <div class="buttonLin-height-m"></div>
                <div class="button_m_code marginTop-10">
                    <div>
                        <img class="codeSize_m" :src="imgObj.downloadCode">
                        <div class="text5c33m marginTop-10">扫描下载旺街邮APP</div>
                    </div>
                    <div class="kanGe"></div>
                    <div >
                        <img class="codeSize_m" :src="imgObj.publicCode">
                        <div class="text5c33m marginTop-10">扫描关注旺街邮公众号</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import homeImg from '@/assets/img/logo-m.png'
    import navigation from '@/views/img/navigation.png'
    import badge from "@/views/img/badge.png";
    import publicCode from "@/views/img/public_code.png";

    import downloadCode from "@/views/img/download_code.png";
    import logoMin from "@/views/img/logo-min.png";
    import axios from "axios";

    export default {
        data() {
            return {
                slide: 0,
                sliding: null,
                pageNumber: 1,
                existNextPage: 0,
                id: this.$route.query.id,
                newData: {},
                imgObj: {homeImg,logoMin,navigation,badge, publicCode, downloadCode},
            }
        },
        methods: {
            onSlideStart() {
                this.sliding = true
            },
            onSlideEnd() {
                this.sliding = false
            },
            onDynamicClick: function (id) {
                this.$router.push({
                    path: '/news', query: {
                        id: id,
                    }
                })

            },
            init() {
                let  newsId=this.id.replace('.html','')
                this.HTTP = axios.create({
                    baseURL: 'https://yourestpro.wonstreet.com',
                    timeout: 1000,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    params: {
                        id: newsId,
                    }
                });


                this.HTTP.post('/br/information/getDetail')
                    .then((res) => {
                        this.newData = res.data.result

                    })

            },
            handleCommand(e) {
                this.$router.push({
                    path: '/', query: {
                        type: e,
                    }
                })
            },
            // onButtonClick: function (e) {
            //     this.showTheme = e;
            //     // document.getElementById(e).scrollIntoView();
            //     this.$router.push({
            //         path: '/', query: {
            //             type: this.showTheme,
            //         }
            //     })
            // },


        },
        created() {
            this.init()

        },
    }
</script>
<style>
    .head-m {
        display: flex;
        flex-direction: row;
        padding: 10px;
    }

    .head-logo-m {
        display: flex;
        flex-direction: row;
        flex: 1;
        justify-items: center;
        align-items: center;
    }

    .head-logo-name-m {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .appName-m {
        font-size: 24px;
        margin-left: 20px;
        color: #333333;
    }

    .appData-m {
        font-size: 18px;
        color: #666666;
        margin-left: 20px;
    }

    .head-img-m {

        padding: 100px;
        background: url('../img/zhichou-banner-m.png') center no-repeat;
        background-size: cover;
        position: relative;
        overflow: hidden;
        text-align: center;
    }

    .headline-m {
        padding: 25px;
        background: url('../img/dynamic_hted_m.png') center no-repeat;
        background-size: cover;
        position: relative;
        overflow: hidden;
        text-align: center;
    }

    .aboutUsCom-m {
        height: 500px;
        background: url('../img/about_us_m.png') center no-repeat;
        background-size: cover;
        position: relative;
        overflow: hidden;
        text-align: center;
    }

    .bossDelivery-m {
        height: 500px;
        background: url('../img/boss_delivery_m.png') center no-repeat;
        background-size: cover;
        position: relative;
        overflow: hidden;
        text-align: center;
    }

    .driverOrders-m {
        height: 450px;
        background: url('../img/driver_orders_m.png') center no-repeat;
        background-size: cover;
        position: relative;
        overflow: hidden;
        text-align: center;
    }

    .contactUs-m {
        height: 280px;
        background: url('../img/contact_us_m.png') center no-repeat;
        background-size: cover;
        position: relative;
        overflow: hidden;
        text-align: center;
    }

    .navigation-m {
        width: 20px;
        height: 16px;
        margin-left: auto;

    }
    .el-dropdown{
        justify-content: center;
        display: flex;
        flex-direction: column;
    }

    .buttonLin_m {
        width: auto;
        height: 4px;
        background-color: #00c395;
        margin-top: 20px;
    }

    .buttonLin-height-m {
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 20px;
        height: 1px;
        background-color: #e8e8e8;
    }

    .button_m {
        padding-top: 10px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    .flex-column_m {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .codeSize_m {
        width: 110px;
        height: 110px;
    }

    .button_m_code {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding-bottom: 20px;
    }

    .text14c33m {
        font-size: 16px;
        color: #333333;

    }

    .text5c33m {
        font-size: 10px;
        color: #333333;

    }

    .flex-column-m {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .marginTop-10 {
        margin-top: 10px;
    }

    .logoImg_m {
        width: 50px;
        height: 50px;
    }

    .textName {
        font-size: 16px;
        color: #333333;
        margin-top: 10px;
    }

    .text799 {
        font-size: 10px;
        color: #999999;
        margin-top: 10px;
    }

    .text5-666 {
        font-size: 10px;
        color: #666666;

    }

    .ganAn {
        display: flex;
        flex-direction: row;

    }

    .badge_m {
        margin-top: 10px;
        height: 15px;
        width: 15px;
    }

    .kanGe {
        width: 20px;
    }

    .buttonCursor_m {
        display: flex;
        flex-direction: row;
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 10px;
    }

    .buttonCursor_m img {
        width: 140px;
        height: 80px;
    }

    .flex-column-m {
        display: flex;
        flex-direction: column;
        margin-right: 5px;
    }

    .flex-column-time {
        display: flex;
        flex-direction: column;
    }

    .el-dropdown-link {
        cursor: pointer;
        color: #409EFF;
    }
    .zhojin{
        flex: 1;
    }
    .edr{
        margin-left: 50px;
        display: flex;
        flex-direction: column;
    }
    .main-m{
        margin-left: 10px;
        margin-right: 10px;
    }
    .fontBold{
        font-weight:bold
    }
</style>
